import "./App.css";
import { Home } from "./screens/Home/Home";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      {<Home />}{" "}
      {
        <Helmet>
          <title>Ivy-League Tutors – We've Been Where You Are</title>
          <meta
            name="North American Tutors, Elite Tutors, IVY Leagues"
            content="A platform for connecting students with experienced tutors for personalized learning."
          />
          <meta
            name="keywords"
            content="tutoring, education, online learning"
          />
          <meta
            property="og:title"
            content="Ivy-League Tutors – We've Been Where You Are"
          />
          <meta
            property="og:description"
            content="Find the best tutors here!"
          />
        </Helmet>
      }
    </div>
  );
}

export default App;
