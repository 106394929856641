import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Analytics } from "@vercel/analytics/react";
import { Home, Tutors, AboutUs, Blogs, BlogContent } from "./screens/index";
import { clarity } from "react-microsoft-clarity";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

// Initialize Microsoft Clarity
clarity.init("nltlirhnim");

// Initialize Meta Pixel
const pixelOptions = {
  autoConfig: true, // Enable automatic event detection
  debug: false, // Disable debug mode
};
ReactPixel.init("3819720181605870", null, pixelOptions);

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView(); // Track page view on route change
  }, [location]);

  return null;
};

const RootApp = () => (
  <Router>
    <Helmet>
      <title>Ivy League Tutors – We've Been Where You Are</title>
      <meta
        name="North American Tutors, Elite Tutors, IVY Leagues"
        content="Online Tutors for AP, IB, SAT, IGCSE, GCSE, and College Admissions — Personalized for Every Student."
      />
      <meta name="keywords" content="tutoring, education, online learning" />
      <meta
        property="og:title"
        content="Ivy League Tutors – We've Been Where You Are"
      />
      <meta property="og:description" content="Find the best tutors here!" />
    </Helmet>
    <TrackPageView />
    <Routes>
      <Route index element={<App />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/tutors" element={<Tutors />} />
      <Route exact path="/aboutUs" element={<AboutUs />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route path="/blogcontent/:id" element={<BlogContent />} />
    </Routes>
    <Analytics />
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootApp />);

// Measure performance
reportWebVitals();
