import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form";
import { Loading } from "../../components/Loading/Loading";
import axios from "axios";

export const Blogs = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://nat-backend.vercel.app/getallblogs"
        );
        if (response.data && Array.isArray(response.data)) {
          setBlogs(response.data);
        } else {
          throw new Error("Invalid response format");
        }
        setLoading(false);
      } catch (error) {
        setError(error.message || "Error fetching blogs");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <p className="text-center text-red-500">{error}</p>
      </div>
    );
  }

  if (!Array.isArray(blogs) || blogs.length === 0) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <p className="text-center">No blogs available.</p>
      </div>
    );
  }

  return (
    <div className="bg-themebgwhite">
      {/* SEO Metadata */}
      <Helmet>
        <title>North American Tutors Blogs</title>
        <meta
          name="description"
          content="Explore insightful blogs on education, tutoring, and academic growth. Learn tips, tricks, and strategies to excel in your studies."
        />
        <meta
          name="keywords"
          content="blogs, education, tutoring, learning tips"
        />
        <meta
          name="author"
          content="Blogs - Ivy-League Tutors – We've Been Where You Are"
        />
        <meta
          property="og:title"
          content="Blogs Ivy-League Tutors – We've Been Where You Are"
        />
        <meta
          property="og:description"
          content="Discover educational blogs to improve your academic journey with practical advice and tips from experts."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.northamericantutors.com/blogs"
        />
        <meta property="og:image" content="/path-to-blog-image.jpg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            name: "Tutoring Blogs",
            description:
              "Explore insightful blogs on education, tutoring, and academic growth. Learn tips, tricks, and strategies to excel in your studies.",
            publisher: {
              "@type": "Organization",
              name: "Ivy-League Tutors – We've Been Where You Are",
              logo: {
                "@type": "ImageObject",
                url: "/path-to-logo.jpg",
              },
            },
            blogPosts: blogs.map((blog) => ({
              "@type": "BlogPosting",
              headline: blog.blogTitle,
              image: blog.coverImg,
              author: {
                "@type": "Person",
                name: blog.authorName,
                description: blog.authorDesc,
              },
              datePublished: blog.date || "2024-01-01",
              description: blog.blogDesc,
              url: `https://www.northamericantutors.com/blogcontent/${blog.slug}`,
            })),
          })}
        </script>
      </Helmet>

      <Header scrollToForm={scrollToForm} />
      <br />
      <br />
      <br />
      <div className="w-full bg-[#f9f9f9] py-[10px]">
        <div className="max-w-[1240px] mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 ss:grid-cols-1 gap-8 px-4 text-black">
            {blogs.map((blog) => (
              <Link key={blog._id} to={`/blogcontent/${blog.slug}`}>
                <div className="bg-white rounded-xl overflow-hidden drop-shadow-md md:h-auto flex flex-col justify-between">
                  <img
                    className="h-40 w-full object-cover"
                    src={blog.coverImg}
                    alt="Blog Cover"
                  />
                  <div className="p-6 flex-1 flex flex-col">
                    <h3 className="font-bold text-xl md:text-2xl my-1 font-Poppins text-[#132043]">
                      {blog.blogTitle}
                    </h3>
                    <p className="text-base md:text-l text-gray-600 font-Poppins flex-grow">
                      {blog.blogDesc}
                    </p>
                    <div className="mt-4">
                      <span className="text-[#e07a5a] font-bold text-sm md:text-base">
                        Category:{" "}
                      </span>
                      <span className="text-[#4e5683] text-sm md:text-base">
                        {blog.category}
                      </span>
                    </div>
                    <div className="mt-6 flex items-center">
                      <img
                        src={blog.authorImg}
                        alt="Author"
                        className="w-8 h-8 rounded-full object-cover mr-4"
                      />
                      <div>
                        <p className="text-[#132043] font-bold text-sm md:text-base">
                          {blog.authorName}
                        </p>
                        <p className="text-[#4e5683] text-xs md:text-sm">
                          {blog.authorDesc}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 text-right">
                      <Link
                        to={`/blogcontent/${blog.slug}`}
                        className="text-[#132043] hover:text-[#e07a5a] font-bold text-sm md:text-base"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Form formRef={formRef} />
      <Footer />
    </div>
  );
};
