import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Loading } from "../../components/Loading/Loading";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Optional, for extra markdown features
import rehypeRaw from "rehype-raw"; // For HTML rendering

export const BlogContent = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { id } = useParams(); // Blog slug
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://nat-backend.vercel.app/getblogbyslug/${id}`
        );
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog:", error.response || error.message);
        setError(error.response ? error.response.data.message : error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);

  if (loading) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <div className="text-center mt-20 text-red-500">
          An error occurred: {error}
        </div>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="text-center mt-20">
        Blog not found or something went wrong.
      </div>
    );
  }

  const {
    coverImg,
    blogTitle,
    blogContent,
    authorImg,
    authorName,
    authorDesc,
    category,
    datePublished,
  } = blog;

  return (
    <div className="bg-white">
      {/* SEO Metadata */}
      <Helmet>
        <title>{blogTitle} Ivy-League Tutors – We've Been Where You Are</title>
        <meta
          name="description"
          content={`Read ${blogTitle} authored by ${authorName}. Explore insights on ${category}.`}
        />
        <meta name="keywords" content={`blog, ${category}, ${blogTitle}`} />
        <meta name="author" content={authorName} />
        <meta
          property="og:title"
          content={`${blogTitle} - Tutoring Platform`}
        />
        <meta property="og:description" content={`Insights on ${category}`} />
        <meta property="og:image" content={coverImg} />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://www.northamericantutors.com/blogcontent/${id}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: blogTitle,
            image: coverImg,
            author: {
              "@type": "Person",
              name: authorName,
              description: authorDesc,
            },
            publisher: {
              "@type": "Organization",
              name: "Ivy-League Tutors – We've Been Where You Are",
              logo: {
                "@type": "ImageObject",
                url: "/path-to-logo.jpg",
              },
            },
            datePublished: datePublished || "2024-01-01",
            articleBody: blogContent,
          })}
        </script>
      </Helmet>

      <Header scrollToForm={scrollToForm} />
      <br />
      <br />

      {/* Blog Title */}
      <div className="max-w-2xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-bold text-[#132043] leading-tight text-center mb-8">
          {blogTitle}
        </h1>

        {/* Author Section */}
        <div className="flex justify-center items-center mb-12">
          <img
            className="w-12 h-12 rounded-full object-cover"
            src={authorImg}
            alt="Author"
          />
          <div className="ml-4">
            <p className="text-gray-700 font-medium">{authorName}</p>
            <p className="text-gray-500 text-sm">{authorDesc}</p>
          </div>
        </div>

        {/* Blog Content */}
        <article className="prose prose-lg md:prose-xl max-w-none leading-8 text-[#132043]">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {blogContent}
          </ReactMarkdown>
        </article>
      </div>

      {/* Footer and Form */}
      <div className="bg-gray-100 mt-10 py-10">
        <Form formRef={formRef} />
      </div>

      <Footer />
    </div>
  );
};
