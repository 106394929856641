import React from "react";
import { Helmet } from "react-helmet";
import Faq from "react-faq-component";
import "../../components/Faq/Faq.css";

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What are your tutoring rates?",
      content: `Introductory Session: <span style="font-weight:bold; color:#4682b4;">$50/Hr</span> (High School), <span style="font-weight:bold; color:#4682b4;">$60/Hr</span> (University). Our founder studied on a full-ride scholarship and, with limited financial resources, was able to put himself through school, owing a lot to his community. Now, our business operates by the motto "Education accessible for all." Book a lesson with us, and if we're a good fit, we'll ensure that budgeting for lessons is your last worry.`,
    },
    {
      title: "How can I track my child's progress?",
      content: `We keep you informed! Track your child's progress with regular reports from their tutor, view lesson plans and schedule weekly calls to discuss their learning journey.`,
    },
    {
      title:
        "Can we schedule a trial session to assess compatibility and teaching style?",
      content: `Certainly! You can absolutely schedule a trial session with one of our tutors to assess compatibility and teaching style. This allows you and your child to experience our tutoring approach firsthand and determine if it aligns with your expectations and learning goals.`,
    },
    {
      title:
        "What is your experience working with students who have learning disabilities?",
      content: `Our tutors are experienced with various learning disabilities and ADHD, using their training to tailor tutoring aligned with your child's IEP/504 plan. They utilize multisensory learning and one-on-one tutoring to cater to each student's unique needs.`,
    },
    {
      title:
        "Do tutors provide assistance with homework or additional resources?",
      content: `Our tutors not only provide assistance with homework but also offer additional resources, such as practice materials and worksheets, to reinforce the learning process. We aim to help build a strong foundation for the subject matter.`,
    },
  ],
};

export const FaqComponent = () => {
  const generateFaqSchema = () => {
    const faqSchema = data.rows.map((faq, index) => ({
      "@type": "Question",
      name: faq.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.content,
      },
    }));
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqSchema,
    };
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* SEO Metadata */}
      <Helmet>
        <title>North American Tutors</title>
        <meta
          name="description"
          content="Explore frequently asked questions about North American Tutors, including rates, tracking progress, trial sessions, and working with students with learning disabilities."
        />
        <meta
          name="keywords"
          content="tutoring FAQ, tutoring rates, trial sessions, homework assistance, learning disabilities"
        />
        <meta name="author" content="North American Tutors" />
        <meta property="og:title" content="FAQs - North American Tutors" />
        <meta
          property="og:description"
          content="Learn more about our tutoring rates, progress tracking, trial sessions, and support for students with learning disabilities."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.northamericantutors.com" />
        <script type="application/ld+json">
          {JSON.stringify(generateFaqSchema())}
        </script>
      </Helmet>

      <div className="faq-style-wrapper">
        <Faq data={data} />
      </div>
    </div>
  );
};
